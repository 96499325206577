import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useDispatch, useSelector} from 'react-redux';
import CardMedia from '@mui/material/CardMedia';
import {Alert, CardActionArea, Chip, Grid} from '@mui/material';
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from "@mui/material/Stack";
import {selectNonSubscribedStrategies, selectStrategiesNotOwned} from "redux/strategy/strategySlice";
import {createSimulation} from "redux/simulation/simulationSlice";

const SimulationSelection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const minRequired = 4
  const allStrategies = useSelector(selectStrategiesNotOwned);
  const [selectedCards, setSelectedCards] = useState([]);

  useEffect(() => {
    if (selectedCards.length === minRequired) {
       selectedCards.map((strategyId) => {
         dispatch(createSimulation({'strategy': strategyId}));
       })
    }
  }, [selectedCards]);

  const handleCardClick = (cardId) => {
    if (selectedCards.includes(cardId)) {
      setSelectedCards(prev => prev.filter(id => id !== cardId));
    } else {
      if (selectedCards.length < minRequired) {
        setSelectedCards(prev => [...prev, cardId]);
      }
    }
  };

  return (
    <Box sx={{ mt: 5 }}>

      <Box  display="flex" alignItems="center" justifyContent="center">
      <Stack direction="row" alignItems="center" gap={1}>
        <InfoOutlinedIcon/>
        <Typography >Pick four strategies to add to your Simulation Account ({selectedCards.length}/{minRequired})</Typography>
      </Stack>
      </Box>

      <Grid container spacing={3} sx={{ mt: 3, display: "flex" }}>

        {allStrategies.map((strategy) => (
          <Grid item lg={2} md={4} sm={6} xs={12}  key={strategy.id} sx={{ display: "flex" }}>
            <Card
              sx={{
                outline: selectedCards.includes(strategy.id) ? `2px solid ${theme.palette.secondary.main}` : 'none',
              }}>
              <CardActionArea onClick={() => handleCardClick(strategy.id)}>

                <CardContent>
                  <Typography gutterBottom variant="h4" sx={{
                    mb: 5,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%'
                  }}>
                    {strategy.name.toUpperCase()}
                  </Typography>
                   <Typography variant="largeText" sx={{fontWeight: 300}} color="secondary" >+54.2 %</Typography><br/>
                <Typography variant="smallText" >Annual Return since Oct 29, 2020</Typography>
                  <Box sx={{ mt: 5}}>
                    <Chip  label={"FEATURES"} size="small" variant="outlined" sx={{mr: 0.5}}/>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}


      </Grid>
    </Box>
  );
};

export default SimulationSelection;
